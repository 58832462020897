import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["editor"];

  loadTemplate(event) {
    const selectedTemplate = event.target.value; // Get the selected template value
    const baseUrl = event.target.dataset.baseUrl; // Fetch the base URL from data attribute

    const url = `${baseUrl}?template=${selectedTemplate}`; // Construct the full URL

    // Fetch the template content
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then((content) => {
        this.updateEditor(content); // Populate TinyMCE editor
      })
      .catch((error) => {
        console.error("Failed to load template:", error);
      });
  }

  updateEditor(content) {
    const editor = tinymce.get(this.editorTarget.id);
    if (editor) {
      editor.setContent(content); // Update the TinyMCE content
    } else {
      console.error("TinyMCE editor not found");
    }
  }
}
